import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/privacyPolicy.js";

const PrivacyPolicyPage = () => {
  return (
    <Layout page="privacy" language="en">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.en.title}</h1>
          <p>{translations.en.paragraph1}</p>
          <p className="bold">{translations.en.paragraph2}</p>
          <p>{translations.en.paragraph3}</p>
          <p className="bold">{translations.en.paragraph4}</p>
          <p>{translations.en.paragraph5}</p>
          <p className="bold">{translations.en.paragraph6}</p>
          <p>{translations.en.paragraph7}</p>
          <p className="bold">{translations.en.paragraph8}</p>
          <p>{translations.en.paragraph9}</p>
          <p className="bold">{translations.en.paragraph10}</p>
          <p>{translations.en.paragraph11}</p>
          <p>{translations.en.paragraph12}</p>
          <p>{translations.en.paragraph13}</p>
          <p>{translations.en.paragraph14}</p>
          <p className="bold">{translations.en.paragraph15}</p>
          <p>{translations.en.paragraph16}</p>
          <p className="bold">{translations.en.paragraph17}</p>
          <p>{translations.en.paragraph18}</p>
          <p>{translations.en.paragraph19}</p>
          <p>{translations.en.paragraph20}</p>
          <p className="bold">{translations.en.paragraph21}</p>
          <p>{translations.en.paragraph22}</p>
          <ul>
            <li>{translations.en.paragraph23}</li>
            <li>{translations.en.paragraph24}</li>
            <li>{translations.en.paragraph25}</li>
            <li>{translations.en.paragraph26}</li>
            <li>{translations.en.paragraph27}</li>
            <li>{translations.en.paragraph28}</li>
            <li>{translations.en.paragraph29}</li>
          </ul>
          <p>{translations.en.paragraph30}</p>
          <p>{translations.en.paragraph31}</p>
          <p>{translations.en.paragraph32}</p>
          <p className="bold">{translations.en.paragraph33}</p>
          <p>{translations.en.paragraph34}</p>
          <p>{translations.en.paragraph35}</p>
          <p>{translations.en.paragraph36}</p>
          <ol>
            <li>{translations.en.paragraph37}</li>
            <li>{translations.en.paragraph38}</li>
            <li>{translations.en.paragraph39}</li>
            <li>{translations.en.paragraph40}</li>
            <li>{translations.en.paragraph41}</li>
            <li>{translations.en.paragraph42}</li>
          </ol>
          <p>{translations.en.paragraph43}</p>
          <p>{translations.en.paragraph44}</p>
          <p>{translations.en.paragraph45}</p>
        </div>
      </main>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const Head = () => (
  <>
    <SeoEN />
    <NoIndex />
  </>
);
